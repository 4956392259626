<template>
  <!-- 待考试 -->
  <div class="toExamined">
    <van-nav-bar
      :title="$t('toExamined.ExaminTxt1')"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-search
      v-model="value"
      shape="round"
      background="#fff"
      :placeholder="$t('toExamined.ExaminTxt2')"
      @search="onSearch"
    />
    <!-- <van-empty v-if="list.length == 0" description="暂无数据" /> -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('toExamined.ExaminTxt3')"
      @load="onLoad"
    >
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        class="examList"
        @click="examinInfo(item)"
      >
        <img v-if="item.csurl != ''" v-lazy="item.csurl" alt="" />
        <img v-else src="@/assets/img/default_bgimg.jpg" alt="" />
        <div class="info" :class="item.ispost ? 'hava_bg' : ''">
          <p class="examName">{{ item.coursename }}</p>
          <span class="examMode">{{ item.coursetype }}</span>
          <div class="footer">
            <span class="date">{{ item.testbt }}</span>
            <div class="right">
              <van-tag :type="
                item.teststyle == '模拟'?'primary':(item.teststyle == '补考'?'danger':'warning')
              ">
                {{ item.teststyle }}
              </van-tag>
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getTestCourseList } from "@api/wxpx.js";
import { searchFunc } from "@/utils";
import { Toast } from "vant";
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      value: "",
      list: [],
      loading: false,
      finished: false,
      oldlist: [],
      form: {
        coursename: "",
      },
    };
  },
  methods: {
    examinInfo(item) {
      if (item.ispost == true) {
        this.$router.push({
          path: "/examination",
          query: { paperid: item.paperid, mautoid: item.autoid, teststyle: item.teststyle},
        });
      } else {
        this.$router.push({
          path: "/examinInfo",
          query: { paperid: item.paperid, mautoid: item.autoid, teststyle: item.teststyle },
        });
      }
    },
    onSearch() {
      var arr = searchFunc(this.value, this.form, this.oldlist);
      if (arr.length > 0) {
        this.list = arr;
        this.value = "";
      } else {
        Toast(this.$t("toExamined.ExaminTxt4"));
      }
    },
    onClickLeft() {
      this.$router.push("/home");
    },
    onClickRight() {},
    onLoad() {
      // 异步更新数据
      getTestCourseList({ empid: this.userInfo.empid }).then((res) => {
        this.list = res.data;
        this.oldlist = this.list;
      });
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.list.length >= 40) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.toExamined {
  width: 100%;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .examList {
    .van-cell__value {
      display: flex;
      img {
        width: 250px;
        height: 155px;
        margin-right: 18px;
      }
      .hava_bg {
        background: #fff url("~@/assets/img/toExamined-end.png") no-repeat;
        background-position: right top;
        background-size: 160px;
      }
      .info {
        flex: 1;
        .examName {
          margin: 0;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
          opacity: 1;
        }
        .examMode {
          color: #2b8df0;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .date {
            color: #999999;
            font-size: 24px;
          }
          .right {
            font-size: 28px;
            font-family: DIN;
            font-weight: bold;
            line-height: 44px;
            color: #999999;
            // span:first-child {
            //   font-size: 48px;
            //   font-family: DIN;
            //   font-weight: bold;
            //   color: #2b8df0;
            // }
            span:last-child {
            }
          }
        }
      }
    }
  }
}
</style>
